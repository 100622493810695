export default [
    {
        part: "CSC-LMS and Courses",
        items: [
            {
                title: 'What is CSC-LMS?',
                text: '<div>Conceived from the thrust of the National Government for digital transformation through digitalized processes, the CSC LMS serves as the official platform of the Civil Service Commission as part of its mandates to continuously capacitate the government workforce by making the Learning and Development initiatives of the commission become more accessible by its clients across the nation through the Civil Service Institute.</div>',
                numberList: []
            },
            {
                title: 'Is the CSC-LMS secured?',
                text: '<div>Yes. Our LMS underwent series of Vulnerability Assessment (VA) and Penetration Testing (PT) conducted by the Department of Information and Communications Technology (DICT) to ensure that the system is safe from cybersecurity vulnerability issues. It also underwent Privacy Impact Assessment (PIA) through the help of the National Privacy Commission (NPC) to ensure that risks associated with privacy issues are addressed prior its launching and that the collection of information from end-users are compliant to RA 10173 otherwise known as the Privacy Act of 2012.</div>',
                numberList: []                
            },
            {
                title: 'What are the types of courses you offer?',
                text: '<div>As of date, our courses are subdivided into three categories namely Leadership Development Courses, Foundational Courses, and Human Resource Management Courses. For specific course titles, you may browse our available course through <a href="https://elearning.csc.gov.ph" target="_blank">https://elearning.csc.gov.ph</a></div>',
                numberList: []              
            },
            {
                title: 'How do I know the investment cost for each training course?',
                text: '<div>The charge for each training course varies. To find out the specific investment cost for each of our courses, feel free to browse our available courses to see exact amount of the investment costs as well as other relevant information such as the course descriptions and topic outlines.</div>',
                numberList: []
            },
            {
                title: 'What is the mode of teaching?',
                text: '<div>As of date, our courses can be accessed via online. Specifically, the mode of delivery is divided into two types namely: Online – Instructor Led, and Online – Self Paced.</div>',
                numberList: []
            },
            {
                title: 'What is the main difference between Online – Instructor Led and Online – Self-Paced Course?',
                text: '<div>Online-Instructor Led Courses are those which are being delivered via virtual meeting platforms such as Zoom, MS Teams, and/or Google Meet. Subject Matter Experts (SME) and the learners may interact through various virtual features embedded in the platforms such as camera and microphone for them to see and converse real-time virtually among others. Online-Self-Paced on the other hand is a mode of delivery by which the learner works on the specific course on his own terms. There is no real-time interaction between the instructor and the learner. Learning journey on self-paced courses are supplemented with videos and/or reading materials. In this mode of delivery, learners track their own learning progress on the course.</div>',
                numberList: []
            },
            {
                title: 'How long will it take me to finish self-paced courses?',
                text: '<div>Our self-paced courses can be accomplished within your own terms and convenience provided that you finish them within the specified timeline as reflected in the <span class="l-primary--text">course description</span>. On the average, you can finish the self-paced courses within <span class="l-primary--text">three (3) to seven (7) days</span>.<div>',
                numberList: []
            },
            {
                title: 'Are all your courses in the LMS accorded with Certificate of Completion (COC)?',
                text: '<div>Yes. Learners who complete the courses and were able to fully satisfy the corresponding requirements such as synchronous and asynchronous activities will receive certificate of completion.</div>',
                numberList: []
            },
            {
                title: 'Are all your courses in the LMS available throughout the year?',
                text: '<div>Unfortunately, our courses in the LMS are being run on a specific month/s within each year. Therefore, the availability of each course depends on the date to which they are scheduled to be conducted.</div>',
                numberList: []
            },
            {
                title: 'Will I get notifications or updates for the courses that will be conducted within a given period?',
                text: '<div>As an end-user, you may visit our LMS from time to time to check for the available courses or check for our announcements on our Official Facebook Page: The Civil Service Institute – CSC.</div>',
                numberList: []
            },
        ]
    },
    {
        part: 'Accessibility',
        items: [
            {
                title: 'Is the CSC-LMS accessible in any device?',
                text: '<div>Yes. The CSC-LMS is accessible through desktop, laptops, tablets, and mobile devises that support web browsers and wireless internet connections or cellular data.</div>',
                numberList: []
            },
            {
                title: 'Does the CSC-LMS works even without internet?',
                text: '<div>As of the date, the CSC-LMS can only be accessed through various devices provided the device is connect to internet or cellular data. But this feature of the LMS being operational even if offline will be included in its future development.</div>',
                numberList: []
            },
            {
                title: 'How do I access the CSC-LMS through other devices?',
                text: '<div>To access the CSC-LMS using other device like mobile phones or tablets, kindly click this link: <a href="https://elearning.csc.gov.ph/" target="_blank">https://elearning.csc.gov.ph/</a>',
                numberList: []
            },
        ]
    },
    {
        part: 'Registration and Account Creation',
        items: [
            {
                title: 'Does the CSC-LMS require online subscription fee prior grant of access?',
                text: '<div>No. The CSC-LMS does not require any subscription for access and/or registration/creation of end-user’s account</div>',
                numberList: []
            },
            {
                title: 'How do I create my account?',
                text: '<div>In order for you to create your own account in the system, kindly follow the steps below:</div>',
                numberList: [
                    {
                        text: '<div>In your device, open a web browser and key-in or click the url: <a href="https://elearning.csc.gov.ph/" target="_blank">https://elearning.csc.gov.ph/</a></div>',
                        list: []
                    },
                    {
                        text: '<div>Once you’re in the landing page, click “sign-up”</div>',
                        list: []
                    },
                    {
                        text: '<div> Provide the following profile information:</div>',
                        list: [
                            'First Name',
                            'Middle Name',
                            'Last Name',
                            'Suffix (if applicable)',
                            'Most active email account',
                            'Password (not of your email account but for this LMS Account) containing minimum of 8 characters which comprises upper case, lower case, numerical and special symbols.',
                            'Retype the password and be sure to remember it.',
                            'Read, understand, and agree to the CSC Data Privacy Statement.',
                            'Click the register button',
                            'A pop-up notification will appear on your screen instructing you to check your delegated email account to validate email account ownership.',
                            'Once successfully validated, you will be redirected to the landing page of the LMS where you will be asked to key-in your password. Supply your password credentials to complete logging-in.'
                        ]
                    }
                ]
            },
            {
                title: 'Can I update my profile information from time to time?',
                text: '<div>As end-user, you are allowed to update your profile/account anytime.</div>',
                numberList: []
            },
            {
                title: 'Is the account that I registered available anytime?',
                text: '<div>Once you have created an account in the CSC-LMS, you may log-in anytime provided, that there is an ample amount of internet speed in your location where you are accessing it.</div>',
                numberList: []
            },
            {
                title: 'Is it possible for my account to become dormant and inaccessible?',
                text: '<div>Dormancy is also part of the current features of the CSC-LMS. Registered end-users can still enjoy its current features even after some period of being inactive provided that the continuous and unstaggered period of being inactive will not exceed one (1) year.</div>',
                numberList: []
            },
            {
                title: 'How many times am I allowed to make consecutive failed log-in attempts?',
                text: '<div>Each of the end-users are allowed to make five (5) consecutive failed log-in attempts.</div>',
                numberList: []
            },
            {
                title: 'If I exceeded the maximum number of consecutive failed log-in attempts, will my account become blocked?',
                text: '<div>Exceeding the maximum allowable failed attempts will require the end-user to wait for 5 minutes before another attempt. In case that the end-users can no longer retrieve their password, they are required to create new one by hitting “Forgot Password”.</div>',
                numberList: []
            },
            {
                title: 'I cannot register, what should I do?',
                text: '<div>In order to successfully register for an account in the CSC-LMS, be sure that you have provided all the necessary information by supplying them in all the boxes where specific information are being required. Unclickable “Register” button means that some of the boxes are unfilled other incorrectly filled-out. In this case, reviewing all information provided is necessary.</div>',
                numberList: []
            },
            {
                title: 'I did not receive the email to verify registration. What should I do?',
                text: '<div>Be sure to check both the inbox and spam folders for the email verification. If these measures did not help, please contact the LMS Administrator through lms@csc.gov.ph</div>',
                numberList: []
            },
            {
                title: 'Will I be logged-out automatically if I left my account hanging?',
                text: '<div>The CSC-LMS will automatically log-out users if the account that was signed-in remained continuously inactive for 10 minutes.</div>',
                numberList: []
            },
        ]
    },
    {
        part: 'Course Enrollment and Payment Procedures',
        items: [
            {
                title: 'How can I enroll to available courses?',
                text: '<div>On your dashboard, browse for courses and select the training applicable based on your Learning and Development (L&D) needs. Once decided on the course to take, click “Add to My List” and a short notification will pop-up on upper right of your screen.</div>',
                numberList: []
            },
            {
                title: 'How do I pay for the course I enrolled?',
                text: '<div>In your Home Screen, click “My List” followed by the course you intent to pay and click “Proceed to Payment". If you are enrolling for a course at your own expense, you will be directed to LandBank’s ePayment Portal. Complete the process to finish enrollment. If your participation will be paid by the agency, you will be required by the system to upload an approved nomination. After completing, you will be notified by the Course Administrator for the billing statement containing the steps to be followed on how to settle the training investment fee.</div>',
                numberList: []
            },
            {
                title: 'What course are available?',
                text: '<div>All available courses are posted in the Landing Page of the LMS inside and outside of the end-user’s account. The courses are categorized into three namely, Leadership Programs, Foundations Programs, and Human Resource Management Programs.</div>',
                numberList: []
            },
            {
                title: 'How will I know the appropriate course for me to enroll?',
                text: '<div>On the CSC-LMS landing page, choose among the three categories suites your general L&D Needs then search among the drop-down list the course that interests you. Click the specific course to find out more about the course through the course description where target participants are also indicated. When inside your user account, browse the course by clicking the visible arrow either from left or right to browse for the courses available. Click the thumbnail of the specific course to view its course description as well as the topic outlines.</div>',
                numberList: []
            },
            {
                title: 'Can I enroll and participate to the course even if I am not yet paid?',
                text: '<div>Participants who will not be able to pay the required training investment fee on or before the payment due date are advised to prepare and send a promissory note to the email account of the designated Course Administrator. Note that promissory notes are being accepted but are subject to the approval of the concerned Office Head.</div>',
                numberList: []
            },
            {
                title: 'I have already paid and/or sent a promissory note but I did not receive any confirmation of my enrollment to the course, what should I do?',
                text: '<div>Log-in to your LMS account and proceed to your dashboard. You shall be able to see all the courses you have enrolled at “My Courses” including their status. If the course enrolled is Instructor-Led you should be able to click the course and see the link to join. If these steps did not work, immediately contact the Course Administrator through the email provided in the course description.</div>',
                numberList: []
            },
            {
                title: 'Can I assign/transfer the course I enrolled and paid to my colleagues should I will not be able to attend due to some valid reasons?',
                text: '<div>Learning and Development (L&D) courses enrolled by a specific end-user/learner can only be accessed with the end-user’s registered LMS Account. Courses which were enrolled and already paid in full are non-transferrable. In case that the learner manifested his/her sudden unavailability on the scheduled run of the course in writing, and that the cause is valid such as due to exigency of service, he/she shall be allowed to have them taken instead on the next batch of the conduct of the same course. </div>',
                numberList: []
            },
        ]
    },
    {
        part: 'Course Completion and Issuance of Certificate',
        items: [
            {
                title: 'How can I get my certificate of completion?',
                text: '<div>Certificate of Completion (COC) will be automatically sent to the learner’s registered email account upon validation of completeness of requirements by the Course Administrator. These requirements include full payment of the training investment fee, attendance, and asynchronous activities. Learners may also generate and download their certificates anytime within their account in the LMS.</div>',
                numberList: []
            },
            {
                title: 'I completed the course but was not able to get my certificate, what should I do?',
                text: '<div>In some instances, learners do not receive or cannot download their certificates on the following reasons:</div>',
                numberList: [
                    {
                        text: '',
                        list: [
                            'The promissory note for payment of the training investment fee is not yet fulfilled',
                            'Course completion is not yet fully validated by the designated Course Administrator',
                            'The learner failed on some of the test assessments and has to retake the them',
                            'The learner failed to complete the requirements such as the complete attendance for Instructor-Led Courses',
                            'The learner failed to provide substantial response to test assessments contacting open ended questions'
                        ]
                    }
                ]
            },
            {
                title: 'If I used my certificate of completion for job application, can it be validated?',
                text: '<div>COCs generated by the CSC-LMS have QR Codes which when scanned will provide the information necessary to assess/validate the veracity of the document.</div>',
                numberList: []
            },
            {
                title: 'Can I include the course I finished through the CSC-LMS in my Personal Data Sheet (PDS)/Resume?',
                text: '<div>All courses completed through the CSC-LMS can be included in the PDS.</div>',
                numberList: []
            },
            {
                title: 'Are the courses I completed can be converted into Continuing Professional Development (CPD) Units?',
                text: '<div>All courses enrolled and completed by the learner through the CSC-LMS has corresponding number of leadership and management training hours. In the absence of a written law or policy which specifically prescribes however, these number of hours of training credits has no equivalence and cannot be converted into nor should be treated as CPD Units.</div>',
                numberList: []
            },
            {
                title: 'Can I get a certified true copy of my certificate of completion?',
                text: '<div>While it may not be necessary due to COC’s feature that allows it to be validated online, learners may request for a certified true copy of the certificates earned.</div>',
                numberList: []
            },
            {
                title: 'I did not finish my self-paced eLearning Course in one-seating. Can I continue it in some other days?',
                text: '<div>Self-paced eLearning courses are designed in such a way that learners can take them on a staggered manner meaning, these courses are not designed to be accomplished into one seating. Nevertheless, learners are not precluded from accomplishing the same earlier than intended provided that the purpose of the learning objectives and learning outcomes are not compromised. However, learners are advised to accomplish these courses on or before the last day of their availability otherwise, no COC will be earned</div>',
                numberList: []
            },
            {
                title: 'I lost the copy of my certificate of completion; can I get a new copy?',
                text: '<div>Learners may access and redownload a copy of the certificates they have earned through the CSC-LMS. These earned certificates can be accessed specifically within the learner’s profile.</div>',
                numberList: []
            }
        ]
    },
    {
        part: 'Notifications and Maintenance',
        items: [
            {
                title: 'Will I get updates on the monthly course offerings as well as on new courses through the CSC-LMS?',
                text: '<div>All L&D public offerings of the CSC will be posted in the CSC-LMS and through the CSI’s Official FaceBook Page so that the end-users/learners are well-informed of the updates.</div>',
                numberList: []
            },
            {
                title: 'Can I get notifications from the CSC-LMS through my registered email?',
                text: '<div>CSC-LMS Administrators as well as Course Administrators may send email notification to the end-users in the form of email blasts.</div>',
                numberList: []
            },
            {
                title: 'Can I log into the system even if the LMS is undergoing system maintenance?',
                text: '<div>The end-users may temporarily be prevented from their access to the LMS when the system is undergoing maintenance to ensure uninterrupted use. You will be notified via email once the maintenance has already concluded to continue access.</div>',
                numberList: []
            },
            {
                title: 'Where can I see the notifications in the landing page of my LMS Account?',
                text: '<div>Notifications can be found at the upper right corner of the landing page inside the end-user’s account. Just click the “Bell” icon to see the notifications. However, these notifications only cover those interactions among the learners, the course and LMS administrators.</div>',
                numberList: []
            },
            {
                title: 'I am not receiving notifications for updates, what should I do?',
                text: '<div>Be sure to check your email’s inbox or spam folder, or log into your account in the LMS. If none of these worked, be sure to contact the LMS Administrator at lms@csc.gov.ph.</div>',
                numberList: []
            }
        ]
    }
]